<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="SM Keys">
      <template v-slot:header>
        <div class="flex justify-between">
          <h3 class="text-lg leading-6 font-medium text-gray-900">SM Keys</h3>
          <asom-button
            v-if="canUpdateSMDutyKeys"
            size="sm"
            variant="success"
            icon="math-plus"
            text="Add new"
            @click="openCreateItemModal"
          />
        </div>
      </template>
      <asom-client-table
        v-if="!isLoading"
        :columns="['no', 'name', canUpdateSMDutyKeys && 'action']"
        :data="tableData"
        :thClasses="tableHeaderClasses"
        :sortableColumns="['name']"
      >
        <template v-slot:no="scopedSlots">{{
          scopedSlots.rowIndex + 1
        }}</template>
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              text="Edit"
              @click="
                openUpdateItemModal(
                  scopedSlots.rowData.smDutyItemId,
                  scopedSlots.rowData.name
                )
              "
            />
            <asom-button
              size="sm"
              variant="error"
              text="Remove"
              @click="
                openRemoveItemModal(
                  scopedSlots.rowData.smDutyItemId,
                  scopedSlots.rowData.name
                )
              "
            />
          </div>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div> </asom-card
    ><asom-card title="Activity Log">
      <asom-client-table
        v-if="!isLoadingActivityLogs"
        :columns="[
          'transactionNo',
          'createdByPersonNo',
          'takeoverByPersonNo',
          'transactionType',
          'dateCreated',
          'createdBy',
          'takeoverBy',
          'remarks',
        ]"
        :sortableColumns="[
          'transactionNo',
          'createdByPersonNo',
          'takeoverByPersonNo',
          'transactionType',
          'dateCreated',
          'createdBy',
          'takeoverBy',
          'remarks',
        ]"
        :data="activityList"
        :searchableDateColumns="['dateCreated']"
      >
        <template v-slot:dateCreated="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:remarks="scopedSlots">
          <asom-tooltip>
            <template #toggleText>
              <span class="overflow-ellipsis line-clamp-2">{{scopedSlots.data}}</span>
            </template>
            <p class="asom-p w-48">{{scopedSlots.data}}</p>
          </asom-tooltip>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </asom-card>
    <asom-modal
      v-model="newItemForm.showModal"
      title="New SM Duty Key"
      size="md"
    >
      <div class="space-y-2">
        <asom-alert
          v-if="newItemForm.error"
          variant="error"
          :error-message="newItemForm.error"
        />
        <asom-form-field
          label="Key Name"
          required
          :state="inputStates('newItemForm.name')"
          error="Name must be between 5-100 characters"
        >
          <asom-input-text
            v-model="newItemForm.name"
            :state="inputStates('newItemForm.name')"
          />
        </asom-form-field>
        <div class="flex flex-row justify-end pt-8 space-x-2">
          <asom-button
            @click="closeCreateItemModal(false)"
            text="Cancel"
            variant="secondary"
          />
          <asom-button
            @click="createItem"
            text="Create"
            :loading="newItemForm.isLoading"
            :disabled="newItemForm.isLoading"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      v-model="updateItemForm.showModal"
      title="Update SM Duty Key"
      size="md"
    >
      <div class="space-y-2">
        <asom-alert
          v-if="updateItemForm.error"
          variant="error"
          :error-message="updateItemForm.error"
        />
        <asom-form-field
          label="Key Name"
          required
          :state="inputStates('updateItemForm.name')"
          error="Name must be between 5-100 characters"
        >
          <asom-input-text
            v-model="updateItemForm.name"
            :state="inputStates('updateItemForm.name')"
          />
        </asom-form-field>
        <asom-form-field
          label="Remarks"
          required
          :state="inputStates('updateItemForm.remarks')"
          error="Remarks must be between 5-100 characters"
        >
          <asom-input-textarea
            v-model="updateItemForm.remarks"
            :state="inputStates('updateItemForm.remarks')"
          />
        </asom-form-field>
        <div class="flex flex-row justify-end pt-8 space-x-2">
          <asom-button
            @click="closeUpdateItemModal(false)"
            text="Cancel"
            variant="secondary"
          />
          <asom-button
            @click="updateItem"
            text="Update"
            :loading="updateItemForm.isLoading"
            :disabled="updateItemForm.isLoading"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      v-model="removeItemForm.showModal"
      title="Confirmation"
      size="md"
    >
      <div class="space-y-2">
        <asom-alert
          v-if="removeItemForm.error"
          variant="error"
          :error-message="removeItemForm.error"
        />
        <p>
          Do you want to remove SM Duty Key
          <strong>{{ removeItemForm.name }}</strong
          >?
        </p>
        <asom-form-field
          label="Remarks"
          required
          :state="inputStates('removeItemForm.remarks')"
          error="Remarks must be between 5-100 characters"
        >
          <asom-input-textarea
            v-model="removeItemForm.remarks"
            :state="inputStates('removeItemForm.remarks')"
          />
        </asom-form-field>
        <div class="flex flex-row justify-end pt-8 space-x-2">
          <asom-button
            @click="closeRemoveItemModal(false)"
            text="Cancel"
            variant="secondary"
          />
          <asom-button
            @click="removeItem"
            variant="error"
            text="Remove"
            :loading="removeItemForm.isLoading"
            :disabled="removeItemForm.isLoading"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getSMKeyList,
  getSMKeyLogs,
  createSMKey,
  updateSMKey,
  removeSMKey,
} from "@/services/shiftHandover.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      isLoading: false,
      isLoadingActivityLogs: false,
      error: null,
      tableData: [],
      activityList: [],
      newItemForm: {
        showModal: false,
        name: null,
        isLoading: false,
        error: null,
      },
      updateItemForm: {
        showModal: false,
        id: null,
        name: null,
        remarks: null,
        isLoading: false,
        error: null,
      },
      removeItemForm: {
        showModal: false,
        id: null,
        name: null,
        remarks: null,
        isLoading: false,
        error: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      lineId: "selectedStation/lineId",
      stationId: "selectedStation/id",
      canUpdateSMDutyKeys: "auth/canUpdateSMDutyKeys",
    }),
    tableHeaderClasses() {
      return {
        name: "w-9/12",
      };
    },
  },
  validations() {
    return {
      newItemForm: {
        name: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(100),
        },
      },
      updateItemForm: {
        id: {
          required,
        },
        name: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(100),
        },
        remarks: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(100),
        },
      },
      removeItemForm: {
        id: {
          required,
        },
        remarks: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(100),
        },
      },
    };
  },
  mounted() {
    if (this.stationId !== null) {
      this.getKeyList();
      this.getActivityLogs();
    }
  },
  methods: {
    get,
    displayUtcDate,
    async getKeyList() {
      this.isLoading = true;
      const resp = await getSMKeyList({
        lineId: this.lineId,
        stationId: this.stationId,
      });
      if (resp.success) {
        this.tableData = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async getActivityLogs() {
      this.isLoadingActivityLogs = true;
      const resp = await getSMKeyLogs({
        lineId: this.lineId,
        stationId: this.stationId,
      });
      if (resp.success) {
        this.activityList = get(resp.payload, "list", []);
        this.isLoadingActivityLogs = false;
        this.error = null;
      } else {
        this.isLoadingActivityLogs = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    openCreateItemModal() {
      this.v$.$reset();
      this.newItemForm.showModal = true;
      this.newItemForm.name = null;
      this.newItemForm.isLoading = false;
      this.newItemForm.error = null;
    },
    openUpdateItemModal(id, name) {
      this.v$.$reset();
      this.updateItemForm.showModal = true;
      this.updateItemForm.id = id;
      this.updateItemForm.name = name;
      this.updateItemForm.remarks = null;
      this.updateItemForm.isLoading = false;
      this.updateItemForm.error = null;
    },
    openRemoveItemModal(id, name) {
      this.v$.$reset();
      this.removeItemForm.showModal = true;
      this.removeItemForm.id = id;
      this.removeItemForm.name = name;
      this.removeItemForm.remarks = null;
      this.removeItemForm.isLoading = false;
      this.removeItemForm.error = null;
    },
    closeCreateItemModal() {
      this.newItemForm.showModal = false;
    },
    closeUpdateItemModal() {
      this.updateItemForm.showModal = false;
    },
    closeRemoveItemModal() {
      this.removeItemForm.showModal = false;
    },

    async createItem() {
      if (this.newItemForm.isLoading) return;
      this.newItemForm.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.newItemForm.$invalid) {
        this.newItemForm.isLoading = true;
        const response = await createSMKey({
          stationId: this.stationId,
          name: this.newItemForm.name,
        });
        if (response.success) {
          this.closeCreateItemModal();
          this.getKeyList();
          this.getActivityLogs();
        } else {
          this.newItemForm.error = response.payload;
        }

        this.newItemForm.isLoading = false;
      } else {
        this.newItemForm.error = "Please complete all required fields";
      }
    },

    async updateItem() {
      if (this.updateItemForm.isLoading) return;
      this.updateItemForm.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.updateItemForm.$invalid) {
        this.updateItemForm.isLoading = true;
        const response = await updateSMKey({
          stationId: this.stationId,
          SMKeys: [
            {
              SMKeyId: this.updateItemForm.id,
              keyName: this.updateItemForm.name,
            },
          ],
          remarks: this.updateItemForm.remarks,
        });
        if (response.success) {
          this.closeUpdateItemModal();
          this.getKeyList();
          this.getActivityLogs();
        } else {
          this.updateItemForm.error = response.payload;
        }

        this.updateItemForm.isLoading = false;
      } else {
        this.updateItemForm.error = "Please complete all required fields";
      }
    },

    async removeItem() {
      if (this.removeItemForm.isLoading) return;
      this.removeItemForm.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.removeItemForm.$invalid) {
        this.removeItemForm.isLoading = true;
        const response = await removeSMKey({
          stationId: this.stationId,
          id: this.removeItemForm.id,
          remarks: this.removeItemForm.remarks,
        });
        if (response.success) {
          this.closeRemoveItemModal();
          this.getKeyList();
          this.getActivityLogs();
        } else {
          this.removeItemForm.error = response.payload;
        }

        this.removeItemForm.isLoading = false;
      } else {
        this.removeItemForm.error = "Please complete all required fields";
      }
    },
  },
};
</script>
